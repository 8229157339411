import Authentication from '@/commons/Authentication';


export default {
    name: "Login",

    data() {
        return {
            selectedEntity: {},
            loginBtnDisable: false,
            lastloggeduserUsername: "",
        }
    },
    created() {
        this.selectedEntity = this.createEntityObj(null);
        this.lastloggeduserUsername = localStorage.getItem("lastloggeduser_username");

        this.selectedEntity = this.createEntityObj({ username: this.lastloggeduserUsername, password: '' });
    },
    computed: {
        statusCode() {
            let msg = "";
            if (!this.$store.getters["app/statusCode"]) {
                msg = "TODO";
            } else {
                msg = this.$store.getters["app/statusCode"].toString();
            }
            return msg;
        },
        statusAdditionalMessage() {
            return this.$store.getters["app/statusAdditionalMessage"] ? this.$store.getters["app/statusAdditionalMessage"] : '';
        },
        snackbarEnabled() {
            return this.$store.getters["app/snackbarEnabled"];
        },
        storeSnackbarEnabled() {
            return this.$store.getters["app/snackbarEnabled"];
        },
    },
    mounted() {
        if (this.lastloggeduserUsername == "") {
            this.$refs.username.focus();
        } else {
            this.$refs.password.focus();
        }
    },
    methods: {
        createEntityObj(entity = null) {
            let returnObj = {};
            returnObj.username = entity === null || entity.username === null ? '' : entity.username;
            returnObj.password = entity === null || entity.password === null ? '' : entity.password;
            return returnObj;
        },
        login() {
            if (this.$refs.loginForm.validate()) {
                this.loginBtnDisable = true;
                let entity = this.createEntityObj(this.selectedEntity);
                Authentication.login(entity)
                    .then((respLogin) => {
                        this.$log.debug("-----respLogin");
                        localStorage.setItem("lastloggeduser_username", entity.username);
                        this.$log.debug(respLogin);
                        if (respLogin.success) {
                            Authentication.getMyDetails()
                                .then(respMyDetails => {
                                    this.$log.debug('---- Login - getMyDetails ----1');
                                    // Authentication.getUserRights()
                                    //     .then(respUserRights => {
                                    //         this.$log.debug('---- Login - getUserRights ----1');
                                    //         if (respUserRights.length > 0) {
                                                this.$router.push("/");
                                                this.$store.commit('app/setStatusCode', "299");
                                        //     }
                                        //     else {
                                        //         this.$store.commit('app/setStatusCode', "norights");
                                        //     }
                                        //     this.$store.commit('app/setSnackbarEnabled', true);
                                        // })
                                        // .finally(() => { })
                                })
                        } else {
                            this.$store.commit('app/setStatusCode', "loginFailed");
                            this.$store.commit('app/setSnackbarEnabled', true);
                        }
                    })
                    .catch(() => {
                        this.$log.debug("========= login error cacth ==========");
                        this.$store.commit('app/setStatusCode', "loginFailed");
                        this.$store.commit('app/setSnackbarEnabled', true);
                    })
                    .finally(() => {
                        this.$log.debug("========= login finally ==========");
                        this.loginBtnDisable = false;
                    });
            }
        }
    },
    watch: {
        snackbarEnabled() {
            this.$store.commit('app/setSnackbarEnabled', this.snackbarEnabled);
            if (this.snackbarEnabled) {
                // Prepare snackbar text
                let localizationTextKey = "status." + this.statusCode;
                let mainText = this.$i18n.t(localizationTextKey);
                let text = (this.statusAdditionalMessage) ? mainText + '<br />' + this.statusAdditionalMessage : mainText;

                if (this.snackbarEnabled) {
                    if (this.statusCode[0] == "2") {
                        this.$vToastify.success(text, this.$i18n.t("common.snackbar.SUCCESS"));
                    } else {
                        this.$vToastify.error(text, this.$i18n.t("common.snackbar.ERROR"));
                    }
                }
            }
            this.$store.commit('app/setSnackbarEnabled', false);
        },
    },

};
